import React, {Component} from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {createMarkup} from '../../shared/utility';
import Cookies from 'js-cookie';
import axios from "../../axios-base";

class ConsentManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            switchTab: false,
            statisticCheck: false,
            personalizationCheck: false,
            performanceCheck: false,
            linkAll: false,
            clientLoad: null,
            clientAction: null
        };
        this.tabHandler = this.tabHandler.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectionConsentHandler = this.selectionConsentHandler.bind(this);
        this.completeConsentHandler = this.completeConsentHandler.bind(this);
        this.clientActionHandler = this.clientActionHandler.bind(this);
    };

    tabHandler = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                switchTab: true
            });
        }
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const name = target.name;
        const checked = target.checked;

        this.setState({
            [name]: checked
        });
    };

    selectionConsentHandler = async() => {
        let date = Date.now();
        await this.setState({
            clientAction: date
        });
        Cookies.set("bigfoot_cookie-consent", true, {expires: 180});
        this.props.consentCallback(this.state);
        axios.post('/analytics/', this.state)
            .then(response => {
                console.debug(response);
            })
            .catch(error => {
                console.debug(error);
            });
    };

    completeConsentHandler = async() => {
        let date = Date.now();
        await this.setState({
            statisticCheck: true,
            personalizationCheck: true,
            performanceCheck: true,
            linkAll: true,
            clientAction: date
        });
        Cookies.set("bigfoot_cookie-consent", true, {expires: 180});
        this.props.consentCallback(this.state);
        axios.post('/analytics/', this.state)
            .then(response => {
                console.debug(response);
            })
            .catch(error => {
                console.debug(error);
            });
    };

    clientActionHandler = () => {
        let date = Date.now();
        this.setState({
            clientAction: date
        });
    };

    componentDidMount() {
        let date = Date.now();
        this.setState({
            clientLoad: date
        });
    }

    render() {
        let privacyPolicyPage = null;
        let privacyPolicyPageMarkup = null;

        if (this.props.pages) {
            privacyPolicyPage = this.props.pages.filter((page) => page.slug === "datenschutz")[0];
            privacyPolicyPageMarkup = privacyPolicyPage.body;
        }
        return (
            <React.Fragment>
                <ModalHeader tag='div' className="border-bottom-0 pb-0">
                    <h4>Cookie Nutzung</h4>
                    <Nav tabs>
                        <NavItem>
                            <NavLink href="#" onClick={() => {
                                this.tabHandler('1');
                            }} active={this.state.activeTab === '1'}>Cookies</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" to="/datenschutz/" tag={NavLink} onClick={() => {
                                this.tabHandler('2');
                            }} active={this.state.activeTab === '2'}>Datenschutzerklärung</NavLink>
                        </NavItem>
                    </Nav>
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Form>
                                <FormGroup check>
                                    <Label for="necessaryCheck" className="text-muted" check>
                                        <Input name="necessaryCheck"
                                               id="necessaryCheck"
                                               type="checkbox"
                                               checked
                                               disabled/>
                                        Notwendig
                                    </Label>
                                    <p className="text-muted">Notwendige Cookies sind zur Funktion unserer Website erforderlich und können nicht deaktiviert werden. Diese Cookies werden als Reaktion auf von Ihnen getätigten Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen.</p>
                                </FormGroup>
                                <FormGroup check>
                                    <Label for="statisticCheck" check>
                                        <Input name="statisticCheck"
                                               id="statisticCheck"
                                               onChange={this.inputChangeHandler}
                                               type="checkbox"
                                               checked={this.state.statistic}/>
                                        Statistik
                                    </Label>
                                    <p>Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.</p>
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId='2'>
                            {privacyPolicyPageMarkup ?
                                <div dangerouslySetInnerHTML={createMarkup(privacyPolicyPageMarkup)}/> : "Loading"}
                            <div/>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.selectionConsentHandler} outline color="dark" block>Auswahl Akzeptieren</Button>
                    <Button onClick={this.completeConsentHandler} color="secondary" block>Alles Akzeptieren</Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

export default ConsentManager;

Cookies.defaultProps = {
    disableStyles: false,
    hideOnAccept: true,
    acceptOnScroll: false,
    acceptOnScrollPercentage: 25,
    onAccept: () => {
    },
    cookieName: "bigfoot_cookie-consent",
    cookieValue: true,
    buttonText: "OK",
    debug: false,
    expires: 30,
    extraCookieOptions: {}
};
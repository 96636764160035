import React from 'react';
import Aux from '../../../hoc/Aux/Aux';
import classes from './ProjectListItem.scss';
import { Row, Col } from 'reactstrap';

const projectListItem = (props) => {

    let projectTitles;
    if(props.subtitle) {
        projectTitles =
            <Aux>
                <span className={classes.Title}>{props.title}</span><br/>
                <span className={classes.Subtitle}>{props.subtitle}</span>
            </Aux>
    } else {
        projectTitles =
            <Aux>
                <span className={classes.Title}>{props.title}</span>
            </Aux>
    }

    return (
            <Row className={classes.Row + ' px-0 py-0 border-bottom ' + props.display} onClick={props.clicked} noGutters>
                <Col xs="3" className="border-right">
                    <Row noGutters>
                        <Col md="6" className="d-flex align-items-center justify-content-center text-primary">
                            <span className={classes.Rank}>{props.rank}</span>
                        </Col>

                        <Col md="6" className="d-flex align-items-center justify-content-center bg-light">
                            <span className={classes.Votes}>{props.voteCount}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs="9" className="d-flex align-items-center">
                    <Row noGutters>
                        <Col>
                            {projectTitles}
                        </Col>
                    </Row>
                </Col>
            </Row>
    );
};

export default projectListItem;

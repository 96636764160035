import React from 'react';
import classes from './ProjectLogoPlaceholder.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const projectLogoPlaceholder = () => (
    <div className={classes.ProjectLogoPlaceholder}>
        <FontAwesomeIcon icon={faUsers} className="text-primary" size="3x"/>
    </div>
);

export default projectLogoPlaceholder;
import React from 'react';
import {Button} from 'reactstrap';
import { Link } from "react-router-dom"
import Aux from '../../hoc/Aux/Aux';
import {isExternalLink, createMarkup} from "../../shared/utility.js";
import classes from './CallToAction.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

const callToAction = (props) => {

    let ctaLink = null;
    let copy = `Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.
                Manchmal benutzt man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um Schriften.`;

    // Todo: Refactor buttonText Prop to linkText because text links for external sites have been added
    if (props.linkUrl && props.buttonText) {
        if (isExternalLink(props.linkUrl)) {
            ctaLink = <Button
                outline={props.outline}
                className="mt-auto btn-block btn-md-inline-block"
                color={props.buttonColor || 'secondary'}
                href={props.linkUrl + '/'}
                target="_blank"
                rel="external noopener">
                <FontAwesomeIcon
                    icon={faExternalLinkSquareAlt}
                    size="lg"
                    className="pr-1"/>
                {props.buttonText}
            </Button>
        } else {
            ctaLink = <Button
                outline={props.outline}
                className="mt-auto btn-block btn-md-inline-block"
                color={props.buttonColor || 'secondary'}
                tag={Link}
                to={props.linkUrl + '/'}>
                {props.buttonText}
            </Button>
        }
    }

    // Replace copy text placeholder with prop value if passed by parent
    if (props.copy) {
        copy = props.copy;
    }

    return (
        <Aux>
            <h2 className={props.bootstrapClass}>{props.headline}</h2>
            <p className={[classes.CallToAction, props.bootstrapClass].join(' ')} dangerouslySetInnerHTML={createMarkup(copy)} />
            {ctaLink}
        </Aux>
    )
};

export default callToAction;

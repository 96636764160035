import React from 'react';
import Aux from '../../../hoc/Aux/Aux';

import {Col, Input, Label, FormFeedback, FormText} from 'reactstrap';

const input = (props) => {
    let inputElement = null;

    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <Input
                    // Todo: Refactor elementConfig spread. There is too much unneeded props present (i.e. columns)
                    {...props.elementConfig}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    value={props.value}
                    onChange={props.changed}
                    valid={!(props.invalid && props.shouldValidate)}/>
            );
            break;

        case ('textarea'):
            inputElement = (
                <Input
                    type="textarea"
                    placeholder={props.elementConfig.placeholder}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    value={props.value}
                    onChange={props.changed}
                    rows="8"/>
            );
            break;

        case ('select'):
            inputElement = (
                <Input
                    type="select"
                    autoComplete={props.elementConfig.autoComplete}
                    value={props.value}
                    onChange={props.changed}>

                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>{option.displayValue}</option>
                    ))}

                </Input>
            );
            break;

        case ('file'):
            if (props.elementConfig.multiple) {
                inputElement = (
                    <Input
                        type="file"
                        value={props.value}
                        name={props.name}
                        onChange={props.changed}
                        multiple/>
                );
            } else {
                inputElement = (
                    <Input
                        type="file"
                        value={props.value}
                        name={props.name}
                        onChange={props.changed}/>
                );
            }
            break;

        case ('checkbox'):
            inputElement = (
                <Input
                    type="checkbox"
                    value={props.value}
                    name={props.name}
                    id={props.name}
                    invalid={!!(props.invalid && props.touched && props.shouldValidate)}
                    onChange={props.changed}/>
            );
            break;

        default:
            inputElement = (
                <Input
                    // Todo: Refactor elementConfig spread. There is too much unneeded props present (i.e. columns)
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}/>
            );
    }

    let inputGroup = null;

    function createMarkup() {
        return {__html: props.elementConfig.label};
    }

    if (props.elementType === 'checkbox') {
        inputGroup = (
            <Col className="mb-3 form-check ml-3" md={props.elementConfig.columns}>
                {inputElement}
                <Label check for={props.name} dangerouslySetInnerHTML={createMarkup()} />
                <FormFeedback>{props.errorMessage}</FormFeedback>
            </Col>
        )
    } else {
        inputGroup = (
            <Col className={props.wrapperCSSClass || 'mb-3'} md={props.elementConfig.columns}>
                <Label>{props.elementConfig.label}</Label>
                {inputElement}
                <FormFeedback>{props.errorMessage}</FormFeedback>
                {props.formText ? <FormText>{props.formText}</FormText> : null}
            </Col>
        )
    }

    return (
        <Aux>
            {inputGroup}
        </Aux>
    );
};

export default input;

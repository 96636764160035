import React from 'react';
import { Col } from 'reactstrap';

const projectListCounter = (props) => {

    return (
        <Col>
            <h3>Wir freuen uns über {props.projectsCount} Teilnehmer!</h3>
        </Col>
    );
};

export default projectListCounter;

import React from 'react';
import { NavItem } from 'reactstrap';
import classes from './NavigationItem.scss';

const navigationItem = (props) => {
    let type = null;

    switch (props.type) {
        case "header":
            type = classes.Header;
            break;
        case "cta":
            type = classes.Cta;
            break;
        case "footer":
            type = classes.Footer;
            break;
        default:
            type = null;
    }

    return (
        <NavItem className={type}>
            {props.children}
        </NavItem>
    )
};

export default navigationItem;

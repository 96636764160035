import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import {Row, Card, CardHeader, CardBody, Collapse, Button} from 'reactstrap';
import { Link } from "react-router-dom"
import {Helmet} from "react-helmet";

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: [],
            cardData: [
                {
                    header: "Hier geht es zur Abstimmung",
                    body: "Sie möchten für Ihren Favoriten abstimmen? Hier entlang:",
                    buttonText: "Jetzt abstimmen",
                    buttonLink: "/projekte/"
                },
                {
                    header: "Hier geht es zur Bewerbung",
                    body:"Sie möchten selbst teilnehmen? Einfach online anmelden:",
                    buttonText: "Jetz bewerben",
                    buttonLink: "/bewerbung/"
                },
                {
                    header: "Alle Informationen zum Ablauf",
                    body:"Sie möchten mehr über den Wettbewerb erfahren?",
                    buttonText: "Jetzt informieren",
                    buttonLink: "/wettbewerb/"
                }
            ]
        }
        this.toggle = this.toggle.bind(this);
    };


    componentDidMount() {
        this.props.pageNotFoundCallback(true);
    }

    componentWillUnmount() {
        this.props.pageNotFoundCallback(false);
    }

    toggle = (e) => {
        let cardNumber = Number(e.target.dataset.cardnumber);
        let collapse = this.state.collapse;
        let index =  collapse.indexOf(cardNumber);
        if (index > -1) {
            collapse.splice(index, 1);
            this.setState({collapse: collapse});
        }
         else {
            collapse.push(cardNumber);
            this.setState({collapse: collapse});
        }
    };


    render(){
        return (
            <React.Fragment>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <Row className="d-flex flex-column m-2">
                    {this.state.cardData.map((card, index, cardData )=> (
                        <Card className="mb-1" key={index}>
                            <CardHeader tag="h5" style={{ cursor: "pointer" }} className={!this.state.collapse.includes(index) ? "border-bottom-0" : ""} onClick={this.toggle} data-cardnumber={index}>
                                {card.header}
                                {
                                    this.state.collapse.includes(index) ?
                                    <FontAwesomeIcon icon={faAngleUp} size="lg" className="float-right" /> :
                                    <FontAwesomeIcon icon={faAngleDown} size="lg" className="float-right" />
                                }
                            </CardHeader>
                            <Collapse isOpen={this.state.collapse.includes(index)}>
                                <CardBody className="clearfix">
                                    <p>{card.body}</p>
                                    <Button
                                        color="secondary"
                                        to={card.buttonLink}
                                        tag={Link}
                                        className="float-right mt-auto btn-block btn-md-inline-block">{card.buttonText}</Button>
                                </CardBody>
                            </Collapse>
                        </Card>
                    ))}
                </Row>
            </React.Fragment>
        )
    }
};

export default PageNotFound;

// Clean up pathname value in location props
// to only include alphanumerical characters
export const cleanLocationProp = (pathname) => {
    let re = /[^a-z0-9+]+/gi;
    if (/^\/$/ig.test(pathname)) { return "home" }
    return pathname.replace(re, '');
};

// Utility function for updating state objects
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

// Create HTML markup i.e. from props
export const createMarkup = (HTMLString) => {
    return {__html: addExternalLinkAttributes(HTMLString)};
};

// Add `rel` attributes to external links in given string
export const addExternalLinkAttributes = (HTMLString) => {
    const re = /(href=.?)(https?)([^>]+)/gi;
    return HTMLString.replace(re, 'rel="external noopener" $&');
};

// Check if link is external link via regex
export const isExternalLink = (linkTarget) => {
    return /https?:\/\//.test(linkTarget);
};

// @Todo: Integrate error messages in utility
// Utility function for checking form field validity
export const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.required) {
        // Check if input field has non-whitespace value
        if (typeof  value === 'boolean') {
            isValid = value && isValid;
        } else {
            isValid = value.trim() !== '' && isValid;
        }
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isSMSCode){
        const pattern = /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZ]+$/i;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
};

/**
 * @Todo construct utility functions for:
 * maxLength (see above)
 * minLength (see above)
 * allowedCharacters (regex?)
 * providerPrefixCheck (function)
 */

export const isValidPrefix = (phoneNumber) => {
    const numberPrefixes =  [
        '0150',
        '0151',
        '0152',
        '01525',
        '0155',
        '0157',
        '0159',
        '0160',
        '0162',
        '0163',
        '0170',
        '0171',
        '0172',
        '0173',
        '0174',
        '0175',
        '0176',
        '0177',
        '0178',
        '0179'
    ];

    for (let i = 0; i <= numberPrefixes.length; i++) {
        console.log(numberPrefixes[i]);
        if (phoneNumber.startsWith(numberPrefixes[i])){
            return true;
        }
    }
    return false;
}

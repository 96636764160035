import React, {Component, Fragment} from 'react';
import ReactDropzone from 'react-dropzone';
import axios from '../../axios-base';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import Gallery from '../../components/Gallery/Gallery.js';
import MediaFile from './MediaFile';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import classes from './MediaUpload.scss'


class MediaUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            modal: false,
            urls: [],
            isLoadingFiles: false
        };

        this.removePromptHandler = this.removePromptHandler.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
        this.removeFileHandler = this.removeFileHandler.bind(this);
        this.fileToRemove = null;
    }

    modalHandler = () => {
        this.setState({modal: !this.state.modal})
    };

    removePromptHandler = file => {
        return () => {
            this.modalHandler();
            this.fileToRemove = file;
        }
    };

    removeFileHandler = () => {
        /**
         * From the react-dropzone maintainer: react-dropzone doesn't manage dropped files. You need to destroy the
         * object URL yourself whenever you don't need the preview by calling window.URL.revokeObjectURL(file.preview);
         * to avoid memory leaks
         */
        this.setState(prevState => ({
            files: prevState.files.filter(file => file.image !== this.fileToRemove.image)
        }), () => {
            console.log("updated files state: ", this.state.files);
        });

        this.setState(prevState => ({
            urls: prevState.urls.filter(url => url !== this.fileToRemove.url)
        }), () => {
            //send updated urls to signup form
            this.props.onUploadHandler(this.state.urls);
        });
        this.modalHandler();
    };

    uploading = (value) => {
        this.setState(prevState => ({
            isLoadingFiles: prevState.isLoadingFiles = value
        }), () => (console.log("is loading files set to: ", this.state.isLoadingFiles)));
    };

    onPreviewDrop = files => {
        if (this.state.files.length === 0) {
            if (files.length === this.state.urls.length) {
                this.uploading(false);
            }
        } else {
            this.uploading(true);
        }

        if (this.state.urls.length !== this.state.files.length) {
            this.uploading(true);
        }
        const arrStore = [];
        files.map(fl => {

            let file = {};
            file.image = fl;
            file.url = "";
            file.loading = true;
            arrStore.push(file);
        });

        this.setState(prevState => ({
            files: [...prevState.files, ...arrStore]
        }), () => {
            this.state.files.slice(this.state.files.length - arrStore.length, this.state.files.length).map((fl, index) => {

                const fd = new FormData();
                const axiosConfig = {
                    onUploadProgress: (progressEvent) => {
                        let progress = (progressEvent.loaded * 100) / progressEvent.total;
                        console.log(progress);
                    }
                };

                fd.append('projectGallery', this.state.files[this.state.files.length - arrStore.length + index].image);
                axios.post('/media', fd, axiosConfig)
                    .then((response) => {
                        this.setState(prevState => ({
                            ...prevState.files[this.state.files.length - arrStore.length + index].url = response.data.projectGallery[0].transforms[0].location,
                            ...prevState.files[this.state.files.length - arrStore.length + index].loading = false
                        }));

                        this.setState(prevState => ({
                            urls: [...prevState.urls, response.data.projectGallery[0].transforms[0].location]
                        }), () => {
                            // Send updated urls to signup form
                            this.props.onUploadHandler(this.state.urls);
                        });

                        if (this.state.urls.length !== this.state.files.length) {
                            this.uploading(true);
                        } else {
                            this.uploading(false);
                        }
                    }).catch(error => {
                    console.log("error: ", error, "Type of error: ", typeof (error))
                })
            })
        });
    };

    render() {
        let preview;

        if (this.state.files) {
            preview = this.state.files.map((file, key) => (
                <MediaFile removePromptHandler={this.removePromptHandler} file={file} key={key}/>
            ));
        }

        return (
            <Fragment>
                <label>Mediengalerie</label>
                <Row>
                    <Col>
                        {this.state.files.length > 0 && (
                            <div>
                                <Gallery isSignup>{preview}</Gallery>
                            </div>

                        )}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div>
                            <Modal className={classes.Modal} centered isOpen={this.state.modal}>
                                <ModalHeader className={classes.ModalHeader}
                                             toggle={this.modalHandler}>Entfernen</ModalHeader>
                                <ModalBody>
                                    Wollen Sie die Datei löschen?
                                    <div>{this.state.remove}</div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button outline color="secondary"
                                            onClick={this.modalHandler}>Abbrechen</Button>{' '}
                                    <Button color="secondary" onClick={this.removeFileHandler}>Löschen</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        <ReactDropzone
                            accept="image/png, image/jpeg"
                            onDrop={this.onPreviewDrop}
                            disabled={this.state.isLoadingFiles}
                            multiple
                            maxSize={1000000}>
                            {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                                <section>
                                    <div {...getRootProps()}
                                         className={classes.Dropzone + ' ' + (this.state.isLoadingFiles ? classes.DropzoneLoading : classes.DropzoneDone)}>
                                        <input {...getInputProps()} />
                                        <FontAwesomeIcon icon={faImage} size="2x"/>
                                        {this.state.isLoadingFiles ? "Bitte warten Sie bis die Bilder hochgeladen wurden" : "Bilder hinzufügen (max. Größe 1MB je Bild)"}
                                    </div>
                                </section>
                            )}
                        </ReactDropzone>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default MediaUpload; 
import React from 'react';
import classes from './GalleryItem.scss';

import { Card, CardImg } from 'reactstrap';

const galleryItem = (props) => {
    return (
        <Card inverse className={classes.Slide}>
            <CardImg src={props.src} alt={props.alt} />
        </Card>
    );
};

export default galleryItem;
import React from 'react';
import classes from './Gallery.scss';

import { CardColumns } from 'reactstrap';

const gallery = (props) => {
    if(props.isSignup){
        return (
            <CardColumns>
                {props.children}
            </CardColumns>
        )
    } else {
        return (
            <CardColumns className={classes.Slider}>
                {props.children}
            </CardColumns>
        )
    }
};

export default gallery;

import React from 'react';
import { Row } from 'reactstrap';
import ContentLoader from "react-content-loader"

// Todo: Match visual placeholder style with final project list item style
const projectListItemPlaceholder = props => (
    <Row>
        <ContentLoader
            height={160}
            width={320}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="75" y="0" rx="4" ry="4" width="250" height="6.4" />
            <rect x="75" y="20" rx="3" ry="3" width="250" height="6.4" />
            <rect x="75" y="40" rx="3" ry="3" width="250" height="6.4" />
            <rect x="75" y="60" rx="3" ry="3" width="250" height="6.4" />
            <rect x="75" y="80" rx="3" ry="3" width="250" height="6.4" />
            <rect x="0" y="0" rx="0" ry="0" width="60" height="60" />
            <rect x="0" y="60" rx="0" ry="0" width="60" height="60" />
        </ContentLoader>
    </Row>
);

export default projectListItemPlaceholder;
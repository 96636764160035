import React from 'react';
import classes from './Page.scss';
import {createMarkup} from "../../shared/utility";
import { Col } from 'reactstrap';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

const page = (props) => {

    library.add(fas);

    /**
     * Use of dom.watch() method to convert any of the icons provided by API to SVG tags
     * To avoid unnecessary css cruft in <head> dom.watch() is confined to this component's
     * function.
     * 
     * The watch() method expects the to-be-watched DOM node to be present before method
     * invokation. Usually the parent component should contain said DOM node
     * 
     * @param {Object} autoReplaceSvgRoot watch for a particular element
     * @param {Object} observeMutationsRoot watch for a particular element
     * https://fontawesome.com/how-to-use/with-the-api/methods/dom-watch
     */
    dom.watch({
        autoReplaceSvgRoot: document.getElementById('dom-watch'),
        observeMutationsRoot: document.getElementById('dom-watch')
    });

    return (
        <Col className="my-3">
            <article id="page-content"> <h1>{props.pageTitle}</h1>
                <div dangerouslySetInnerHTML={createMarkup(props.pageBody)} className={classes.Page}/>
            </article>
        </Col>
    )
};

export default page;
import React from "react";
import styles from "./CarouselAssets.scss";

/**
 * #96e3fa lightest
 * #5fcced lighter
 * #00a2d4 base
 * #009dd4 base
 */

function Icon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 180">
        <path
            fill={styles.primaryColorLightest}
            d="M4.9 174H198v-4.5c0-14.2-9.9-25.8-22.1-25.8h-.5c-2.6-16-14.6-28.1-29-28.1a24.7 24.7 0 00-8.6 1.5c-6.7-15.4-20.4-25.9-36.2-25.9s-29 10.2-35.8 25.2a24.8 24.8 0 00-6.1-.7c-14.5 0-26.6 12.3-29.1 28.4a22 22 0 00-3.6-.4c-12.2 0-22.1 11.5-22.1 25.8v4.5z"
        />
        <path
            fill={styles.primaryColorLighter}
            d="M72.1 174h193.4v-3.7a22.1 22.1 0 00-22.1-22.1h-.5a29.6 29.6 0 00-29.1-24.1 27.1 27.1 0 00-8.6 1.3 40.6 40.6 0 00-72.1-.6 30.8 30.8 0 00-6.1-.6 29.5 29.5 0 00-29.1 24.4l-3.6-.3a22.1 22.1 0 00-22.1 22.1v3.6z"
        />
        <path
            fill={styles.primaryColorLightest}
            d="M142.5 174h153.6v-2.8c0-8.9-7.9-16-17.6-16h-.4c-2-9.9-11.6-17.5-23.1-17.5a24.8 24.8 0 00-6.8.9c-5.3-9.6-16.2-16.1-28.8-16.1s-23.1 6.3-28.5 15.6a26.1 26.1 0 00-4.8-.5c-11.6 0-21.2 7.6-23.1 17.7l-2.9-.2c-9.7 0-17.6 7.2-17.6 16v2.9z"
        />
        <path
            fill={styles.primaryColorBase}
            d="M67.4 10.1h2.3l2 5.7.8 2.3h.1l.7-2.3 2.1-5.7h2.3v11.7h-1.9v-5.4c0-1 .1-2.6.2-3.7h-.1l-.9 2.7-1.9 5.2h-1.3l-1.9-5.2-.9-2.7h-.1c.1 1.1.2 2.7.2 3.7v5.4h-1.9l.2-11.7zm13.2 0h2v11.7h-2zm7.4 1.8h-3.3v-1.8h8.7v1.8h-3.3v9.9h-2v-9.9zm10.3 4c0-3.8 2.4-6.1 5.3-6.1a4.3 4.3 0 013.3 1.5l-1.1 1.3a3.2 3.2 0 00-2.2-1c-1.9 0-3.3 1.6-3.3 4.2s1.3 4.3 3.2 4.3A3.8 3.8 0 00106 19l1.2 1.2a4.4 4.4 0 01-3.7 1.7c-2.8 0-5.2-2-5.2-6zm9.6 0c0-3.8 2.2-6 5.1-6s5.1 2.3 5.1 6-2.2 6.1-5.1 6.1-5.1-2.4-5.1-6.1zm8.3 0c0-2.7-1.1-4.2-3-4.2s-3.1 1.5-3.1 4.2 1.1 4.3 3.1 4.3 3-1.7 3-4.3zm4.5-5.8h3c3.6 0 5.7 1.9 5.7 5.7s-2.1 5.8-5.6 5.8h-3.1zm2.9 10c2.3 0 3.7-1.3 3.7-4.2s-1.4-4-3.7-4h-.9v8.2zm8.1-10h7v1.8h-4.9v2.9h4.2v1.8h-4.2V20h5v1.8h-7.1zm15.4 0h2.4l3.8 11.7h-2.1l-1.8-6.3a23.6 23.6 0 01-1-3.8h-.1c-.4 1.3-.7 2.7-1.1 3.8l-1.7 6.3h-2.2zm-1.5 6.8h5.4v1.7h-5.4zm9.1-6.8h3.7c2.4 0 4.2.7 4.2 2.9a2.5 2.5 0 01-1.6 2.5v.2a2.7 2.7 0 012.3 2.8c0 2.3-1.9 3.4-4.5 3.4h-4.1zm3.6 4.8c1.7 0 2.3-.6 2.3-1.7s-.8-1.5-2.3-1.5h-1.5v3.2zm.3 5.2c1.6 0 2.6-.6 2.6-1.9s-.9-1.8-2.6-1.8h-1.8v3.7zm5.7.1l1.1-1.4a4.6 4.6 0 003.1 1.3c1.2 0 2-.6 2-1.5s-.8-1.3-1.8-1.7l-1.6-.6a3.4 3.4 0 01-2.4-3.1c0-1.9 1.6-3.3 3.9-3.3a4.8 4.8 0 013.6 1.6l-1.1 1.2a3.7 3.7 0 00-2.5-1c-1.1 0-1.8.5-1.8 1.4s.9 1.3 1.9 1.7l1.6.6c1.4.6 2.4 1.4 2.4 3.2s-1.6 3.4-4.2 3.4a5.6 5.6 0 01-4.2-1.8zm12.4-8.3h-3.3v-1.8h8.8v1.8h-3.4v9.9h-2.1zm7.4-1.8h2.1v11.7h-2.1zm5 0h2.3l2 5.7.8 2.3h.1a20.9 20.9 0 01.8-2.3l2-5.7h2.3v11.7h-1.9v-5.4c0-1 .1-2.6.2-3.7h-.1l-.9 2.7-1.9 5.2h-1.3l-1.9-5.2-.9-2.7h-.1c.1 1.1.3 2.7.3 3.7v5.4h-2l.2-11.7zm13.3 0h2.3l2.1 5.7c.2.8.5 1.5.7 2.3h.1a20.9 20.9 0 00.8-2.3l2-5.7h2.3v11.7h-1.9v-5.4c0-1 .2-2.6.3-3.7h-.1l-.9 2.7-1.9 5.2h-1.3l-1.9-5.2-.9-2.7h-.1c.1 1.1.2 2.7.2 3.7v5.4h-1.9V10.1zm13.3 0h7v1.8h-5v2.9h4.2v1.8h-4.2V20h5.1v1.8h-7.1zm9.5 0h2.2l3.5 6.5 1.2 2.5h.1c-.1-1.1-.3-2.7-.3-3.9v-5.1h1.9v11.7h-2.1l-3.6-6.6-1.1-2.5h-.2a26.9 26.9 0 01.3 4v5.1h-1.9z"
            data-name="schrift"
        />
        <path
            fill={styles.primaryColorBase}
            d="M119.4 113h-5.8v-1.8h5.8zm-10.8 0h-6.1v-1.8h6.1zm-11.1 0h-6.1v-1.8h6.1zm-11.1 0h-6.1v-1.8h6.1zm-11.1 0h-6v-1.8h6zm-11 0h-6.1v-1.8h6.1zm-11.1 0h-6.1v-1.8h6.1zm-11.1 0h-6v-1.8h6zm-11 0H25v-1.8h6.1zm88.2-5h-5.9v-1.8h5.9zm-10.9 0h-6.1v-1.8h6.1zm-11.1 0h-6.1v-1.8h6.1zm-11.1 0h-6v-1.8h6zm-11 0h-6.1v-1.8h6.1zm-11.1 0H58v-1.8h6.1zM53 108h-6v-1.8h6zm66.1 10.4h-5.7v-1.8h5.7zm-10.7 0h-6.1v-1.8h6.1zm-11.1 0h-6v-1.8h6zm-11 0h-6.1v-1.8h6.1zm-11.1 0h-6.1v-1.8h6.1zm-11.1 0h-6v-1.8h6zm-11 0H47v-1.8h6.1zm-11.1 0h-6.1v-1.8H42zm-11.1 0h-6v-1.8h6zm-11 0h-6.1v-1.8h6.1zm-11.1 0H2.7v-1.8h6.1zM177.4 111.2h5.9v1.8h-5.9zm10.9 0h6v1.8h-6zm11 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm11 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm-88.3-4.9h5.9v1.8h-5.9zm10.9 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm11 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm-66.1 10.3h5.7v1.8h-5.7zm10.7 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm11 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm11 0h6.1v1.8h-6.1zm11.1 0h6.1v1.8h-6.1zm11.1 0h6v1.8h-6zm11 0h6.1v1.8H288z"
        />
        <g data-name="Ebene 5">
          <rect
              width="160.3"
              height="124.95"
              x="68.7"
              y="37.6"
              fill="#fff"
              stroke={styles.primaryColorBase}
              strokeMiterlimit="10"
              strokeWidth="2"
              rx="8.5"
          />
          <path
              fill={styles.primaryColorBase}
              d="M206.2 44.3a2.1 2.1 0 102 2.1 2.1 2.1 0 00-2-2.1zm5.9 0a2.1 2.1 0 102 2.1 2.1 2.1 0 00-2-2.1z"
          />
          <circle cx="218" cy="46.4" r="2" fill={styles.primaryColorBase}/>
          <path
              fill="none"
              stroke={styles.primaryColorBase}
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M68.9 53h159.6"
          />
          <path fill={styles.primaryColorLightest} d="M81.7 69.8h139.4v31.39H81.7z"/>
          <path
              style={{ isolation: "isolate" }}
              fill="#fff"
              stroke={styles.primaryColorBase}
              strokeMiterlimit="10"
              strokeWidth="0.75"
              d="M81.8 127.5h63.7v10.49H81.8z"
          />
          <path
              fill={styles.primaryColorBase}
              d="M142.6 153.1H82.9a1.5 1.5 0 01-1.5-1.5v-7.1a1.5 1.5 0 011.5-1.5h59.7a1.4 1.4 0 011.4 1.5v7.1a1.3 1.3 0 01-1.4 1.5z"
          />
          <path
              fill={styles.primaryColorLightest}
              d="M155.6 103.8h64.9v34.38h-64.9zM81.5 58.7h139.6v7.15H81.5zm35.6 50.9h26.6v5.63h-26.6z"
          />
          <path
              fill={styles.primaryColorLight}
              d="M116.1 80a5.7 5.7 0 00-.5-2.6 9.3 9.3 0 00-2.6-3.9c-.4-.3-3.5-3.1-7.7-2.2a8.8 8.8 0 00-4.4 2.4c-5.2 4.9-2.1 11.5-2.1 11.5L88 94.5c-.3.2.1.7.1.7l1.3 1.6a1 1 0 001.1.1l1.5-1.3 8.9-7.6a10.4 10.4 0 004 2.2A10 10 0 00112 89a10.2 10.2 0 004.1-9zm-9.8 8.5c-4.1-.5-6.6-4.4-6.4-8.6s4-7.4 7.9-6.9a7.7 7.7 0 016.4 8.5c-.5 4.2-4 7.4-7.9 7z"
          />
          <path
              fill={styles.primaryColorBase}
              d="M87 129.1h2.2l2.2 6.9h-2l-.3-1.5h-2l-.3 1.5h-2zm.4 4h1.3v-.5c-.2-.6-.3-1.5-.5-2.2H88c-.1.7-.3 1.6-.4 2.2zm4.1 2.1l.8-1.2a2.4 2.4 0 001.4.6c.6 0 1-.2 1-.6s-.2-.8-1.7-.8v-1.3c1.2 0 1.5-.3 1.5-.7s-.2-.7-.7-.7a1.6 1.6 0 00-1.2.6l-.9-1.1a2.9 2.9 0 012.2-.9c1.5 0 2.5.7 2.5 1.9a1.6 1.6 0 01-1.1 1.5 1.6 1.6 0 011.3 1.6c0 1.3-1.2 2-2.7 2a3 3 0 01-2.4-.9zm6.4-6.1h4.5v1.5h-2.6v1.1h2.2v1.5h-2.2v1.3h2.7v1.5h-4.6zm7.2 3.3l-1.8-3.3h2.1l.5 1.1.5 1.1a11.7 11.7 0 01.5-1.1l.4-1.1h2l-1.8 3.4 1.9 3.5h-2.1l-.6-1.2a6.1 6.1 0 00-.5-1.2l-.5 1.2-.5 1.2h-2zm7.5 2.1h-2.9v-1.3l2.3-4h2.4v3.9h.7v1.4h-.7v1.5h-1.8zm0-1.4v-.8c0-.4.1-1.1.1-1.6h-.1a6.1 6.1 0 01-.5 1.2l-.6 1.2zm6.1-2.3h-2.9v-1.6h4.9v1.2c-1.6 1.8-1.8 3-2 5.6h-1.8a8.2 8.2 0 011.8-5.2zm3.2-1.7h2.6c1.4 0 2.6.3 2.6 1.7a1.6 1.6 0 01-.8 1.5 1.6 1.6 0 011.2 1.6c0 1.5-1.3 2.1-2.8 2.1h-2.8zm2.5 2.7c.6 0 .9-.3.9-.7s-.3-.6-.9-.6h-.6v1.3zm.2 2.8c.7 0 1-.3 1-.8s-.3-.7-1-.7h-.8v1.5zm3.7-2a3.5 3.5 0 013.4-3.7 2.7 2.7 0 012.1 1l-.9 1.1a1.8 1.8 0 00-1.2-.5c-.8 0-1.5.8-1.5 2s.6 2 1.5 2a1.9 1.9 0 001.3-.5l.9 1.1a2.9 2.9 0 01-2.3 1 3.2 3.2 0 01-3.3-3.5z"
          />
          <path fill={styles.primaryColorLightest} d="M82.7 103.7h27v19.78h-27z"/>
          <path
              fill="#fff"
              stroke={styles.primaryColorBase}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M160.2 132.4a4 4 0 00-1.9 5.7l-1-1.7a4 4 0 00-5-1.8 4 4 0 00-1.9 5.7l-1-1.7a4 4 0 00-5-1.8 4 4 0 00-1.9 5.7l-8.6-14.5a3.9 3.9 0 10-6.8 4l15.6 26.3a.4.4 0 01-.6.6l-5.9-3.5a4 4 0 00-5.4 1.1 3.9 3.9 0 001.1 5.4 99.7 99.7 0 0011.8 6.6c6.5 2.9 13.9 6.5 20.1 4.5 10.2-3.4 10.6-9.8 10.6-13a44.9 44.9 0 00-5.4-19.4l-3.7-6.3a4.1 4.1 0 00-5.1-1.9z"
          />
          <path
              fill={styles.primaryColorLightest}
              d="M117.1 109.6h26.6v5.63h-26.6zm-34.4-5.9h27v19.78h-27z"
          />
          <path
              fill={styles.primaryColorLightest}
              d="M117.1 109.6h26.6v5.63h-26.6zm-34.4-5.9h27v19.78h-27z"
          />
        </g>
      </svg>
  );
}

export default Icon;

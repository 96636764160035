import React, { Component } from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectListItemPlaceholder from '../../components/UI/Placeholder/ProjectListItemPlaceholder/ProjectListItemPlacholder';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import ProjectListItem from './ProjectListItem/ProjectListItem';
import ProjectListCounter from './ProjectListCounter/ProjectListCounter';
import CallToAction from '../../components/CallToAction/CallToAction';
import classes from './ProjectList.scss';
import axios from '../../axios-base';
import { Link } from 'react-router-dom';
import { Row, Col, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {default as CustomInput}  from '../../components/UI/Input/Input';
import {cleanLocationProp} from '../../shared/utility';

class ProjectList extends Component {

    constructor(props){
        super(props);
        this.state = {
            projectsCount: 0,
            error: false,
            projects: null,
            selectedProjectId: null,
            phrase: '',
            categoryOptions: null,
            categorySelected: '',
            cta: null
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.categoryHandler = this.categoryHandler.bind(this);
    }

    componentDidMount () {
        axios.get('/cta/',{
            params: {
                page: cleanLocationProp(this.props.location.pathname)
            }})
            .then(response => {
                    this.setState({cta: response.data[0]});
            })
            .catch(error => {
                this.setState({error: true});
            });

        axios.get('/projects/')
            .then(response => {
                this.setState({projects: response.data});
                this.setState({projectsCount: response.data.length});
            })
            .catch(error => {
                this.setState({error: true});
            });

        axios.get('/options/')
            .then(response => {
                    let availableCategories = response.data[0].projectForm.projectCategory;
                    if(availableCategories && availableCategories.elementConfig.options.length > 1){
                        let allCategoriesOption = {value: "", displayValue: "Alle"};
                        availableCategories.elementConfig.options.push(allCategoriesOption);
                        availableCategories.elementConfig.label = "Wählen Sie eine Projektkategorie";
                        this.setState({categoryOptions: availableCategories});
                    }
            })
            .catch(error => {
                this.setState({error: true});
            });
    }

    onChangeHandler = (e) => {
        let searchString = e.target.value;
        this.setState({
            phrase: searchString,
            categorySelected: ''
        });
    };

    categoryHandler = (e) => {
        if(e.target.value !== this.state.categorySelected) {
            this.setState({
                categorySelected: e.target.value,
                phrase: ''
            });
        }
    };


    render() {

        let categoryForm;
        if (this.state.categoryOptions){
            categoryForm = (
                <CustomInput
                    elementType={this.state.categoryOptions.elementType}
                    elementConfig={this.state.categoryOptions.elementConfig}
                    value={this.state.categorySelected}
                    changed={this.categoryHandler}
                    shouldValidate={this.state.categoryOptions.valid}
                />
            );
        }

        let projects = (
            <Aux>
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
            </Aux>
        );

        if (this.state.projects) {
            projects = this.state.projects
                .filter((project) => {
                    if (this.props.votingPhases.voteStartedFinale) {
                        return project.isFinalist;
                    } else {
                        return project;
                    }
                })
                .map(project => {
                    let projectVoteCount = '-';
                    let projectRank = '-';

                    if (this.props.votingPhases.voteStartedFinale) {
                        if(project.voteCountFinale) { projectVoteCount = project.voteCountFinale; }
                        if(this.props.votingPhases.voteStarted&& project.rank) { projectRank = project.rank; }
                    } else {
                        if(this.props.votingPhases.voteStarted&& project.voteCount) { projectVoteCount = project.voteCount; }
                        if(this.props.votingPhases.voteStarted&& project.rank) { projectRank = project.rank; }
                    }
                    if (this.state.categorySelected === project.projectCategory || this.state.categorySelected === ''){
                        return (
                            // Todo: Deep-Link zur Ressource definieren und Pretty-Link erstellen
                            <Link className={classes.ProjectListLink} to={'./' + project._id} key={project._id}>
                                <ProjectListItem
                                    display={project.contestantName.toLowerCase().includes(this.state.phrase.toLowerCase()) ? "" : "d-none"}
                                    rank={projectRank}
                                    title={project.contestantName}
                                    subtitle={project.projectTitle}
                                    voteCount={projectVoteCount}
                                />
                            </Link>
                        );
                    }
                });
        }

        let cta = <CallToActionPlaceholder/>;

        if (this.state.cta){
            cta = <CallToAction
                headline={this.state.cta.headline}
                copy={this.state.cta.copy}
                buttonText={this.state.cta.buttonText}
                buttonColor={this.state.cta.buttonColor}
                linkUrl={this.state.cta.linkUrl}
                outline={this.state.cta.outline} />
        }

        return (
            <Aux>
                {/* CallToAction */}
                <Row className="my-3">
                    <Col>
                        {cta}
                    </Col>
                </Row>
                <Row className="my-2">
                    <ProjectListCounter projectsCount={this.state.projectsCount}/>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <Label for="Teilnehmer suchen">Suchen Sie nach einem Teilnehmernamen</Label>
                        <div className={classes.SearchWrapper + " position-relative"}>
                            <FontAwesomeIcon className={classes.SearchIcon} icon={faSearch}/>
                            <Input autoFocus={true} onChange={this.onChangeHandler} className={classes.SearchField + " mr-sm-2"} placeholder="Teilnehmer suchen" type="search" aria-label="Teilnehmer suchen" value={this.state.phrase}/>
                        </div>
                    </Col>
                        {categoryForm}
                </Row>
                <Row>
                    <Col className="px-0 p-lg-3">
                        {projects}
                    </Col>
                </Row>
            </Aux>
        );
    }
}

export default ProjectList;

import React from 'react';
import {Col} from 'reactstrap';
import Countdown from 'react-countdown-now';

let daysInHours = false;

const countdown = (props) => {

    // Set countdown date
    let phaseDate = '';
    let countdownMessage = '';
    if (!props.votingPhases.voteStarted) {
        // the voting has NOT yet started
        phaseDate = props.votingPhases.voteStart;
        countdownMessage = 'bis zur Abstimmung';
    } else if (!props.votingPhases.voteEnded) {
        // the voting has started
        if (!props.votingPhases.hasFinale) {
            // it will NOT have a finale
            phaseDate = props.votingPhases.voteEnd;
            countdownMessage = 'bis zum Ende';
        } else {
            // it will have a finale
            if (props.votingPhases.voteStartedFinale) {
                // the finale started
                phaseDate = props.votingPhases.voteEnd;
                countdownMessage = 'bis zum Ende';
            } else {
                // the finale has not yet started
                phaseDate = props.votingPhases.voteStartFinale;
                countdownMessage = 'bis zum Finale';
            }
        }
    } else {
        countdownMessage = 'ist beendet';
    }

    // Countdown rendering function
    const renderer = ({days, hours, minutes, seconds}) => {
        if (!props.votingPhases.voteEnded) {
            if (days < 2) {
                daysInHours = true;
                return <span>Noch {hours}:{minutes}:{seconds + ' ' + countdownMessage} </span>; // until vote start 
            } else {
                return <span>Noch {days + ' Tage ' + countdownMessage} </span>;
            }
        } else {
            return <span>Der Wettbewerb ist beendet</span>; // voting has ended
        }
    };

    return (
        <Col className={'text-white bg-primary px-0 py-1 text-center'}>
            <Countdown
                date={phaseDate}
                renderer={renderer}
                daysInHours={daysInHours}
            />
        </Col>
    )
};

export default countdown;

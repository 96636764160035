import React, { Component } from "react";
import { Button, Card, CardImg, CardImgOverlay, Spinner} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classes from "./MediaFile.scss";

class MediaFile extends Component{
    render(){

        return (
            <Card className={this.props.file.loading ? classes.MediaFile + " " + classes.Loading : classes.MediaFile}>
            <CardImg
                className={classes.CardImg}
                top
                width="100%"
                src={URL.createObjectURL(this.props.file.image)}
                alt="Card image cap" />
            <CardImgOverlay  className={classes.cardImageOverlay}>
                <div className={this.props.file.loading ? classes.spinnerParentShow : classes.spinnerParentHide}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />{' '}
                </div>
                <div className={classes.deleteArea}>
                    <Button onClick={this.props.removePromptHandler(this.props.file)} color="danger" className={classes.buttonDelete} block><FontAwesomeIcon className={classes.icon} icon={faTimes} size="2x"/></Button>
                </div>
            </CardImgOverlay>
        </Card>)
    }
}

export default MediaFile;